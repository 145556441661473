html {
    height: 100%;
}

body {
    min-height: 100%;
}

#root {
    margin-top:0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    background-image: linear-gradient(to top, #fafaff, #fff);
    /*background-color: #EEF2F7;
    background-image: radial-gradient( circle farthest-corner at 47% 21.7%, rgb(255, 255, 255) 0%, #EEF2F7 100.2% );
    background-repeat:no-repeat;
    background-attachment: fixed;*/
}

.text-confidence-1 {
    background-color: rgba(255, 0, 0, 0.5);
}
.text-confidence-2 {
    background-color: rgba(255, 10, 0, 0.5);
}
.text-confidence-3 {
    background-color: rgba(255, 20, 0, 0.5);
}
.text-confidence-4 {
    background-color: rgba(255, 30, 0, 0.5);
}
.text-confidence-5 {
    background-color: rgba(255, 70, 0, 0.5);
}
.text-confidence-6 {
    background-color: rgba(255, 100, 0, 0.5);
}
.text-confidence-7 {
    background-color: rgba(255, 150, 0, 0.5);
}
.text-confidence-8 {
    background-color: rgba(172, 101, 11, 0.61);
}
.text-confidence-9 {
    background-color: rgba(167, 146, 8, 0.6);
}
.text-confidence-10 {
    background-color: rgba(48, 170, 6, 0.36);
}

.dot {
    display:inline-block;
    width:6px;
    height:6px;
    border-radius:50%;
    margin-right:6px;
    background:#303131;
    animation: wave 1.1s linear infinite;
    margin-left:8px;
    background-color: #774561;
}

.dot:nth-child(2) {
    animation-delay: -0.8s;
    margin-left:0;
    background-color: #5f4970;
}

.dot:nth-child(3) {
    animation-delay: -0.5s;
    margin-left:0;
    margin-right:8px;
    background-color: #3a4663;
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}
	30% {
		transform: translateY(-7px);
	}
}